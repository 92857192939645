import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { clearUserData } from "./userData";
import authService from "../services/auth.service";

const auth = localStorage.getItem("auth");
const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      const response = await authService.register(user);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "auth/delete",
  async (userId, thunkAPI) => {
    try {
      const response = await authService.deleteUser(userId);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/update",
  async (user, thunkAPI) => {
    try {
      const response = await authService.updateUser(user);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (user, thunkAPI) => {
    try {
      const response = await authService.login(user);
      console.log("Thunk Called, Response: ",response);
      if (response.data.access_token) {
        localStorage.setItem("auth", response.data.access_token);
      }
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      console.log("Thunk Response: ",response);
      // return { user: response.data };
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (user, thunkAPI) => {
    try {
      const response = await authService.refreshToken(user);
      if (response.data.access_token) {
        localStorage.setItem("auth", response.data.access_token);
      }
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return { user: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const sendResetPasswordLink = createAsyncThunk(
  "auth/sendResetPasswordLink",
  async (data, thunkAPI) => {
    try {
      const response = await authService.sendResetPasswordLink(data);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, thunkAPI) => {
    try {
      const response = await authService.resetPassword(data);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (args, thunkAPI) => {
  await authService.logout();
  thunkAPI.dispatch(clearUserData());
});

const initialState = auth
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
    },
    [register.rejected]: (state, action) => {
    },
    [deleteUser.fulfilled]: (state, action) => {
    },
    [deleteUser.rejected]: (state, action) => {
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [updateUser.fulfilled]: (state, action) => {},
    [updateUser.rejected]: (state, action) => {},
  },
});

const { reducer } = authSlice;
export default reducer;
